<template>
  <div>
    <template v-if="header">
      <button
        type="button"
        class="form-button"
        :class="{ 'form-button--disabled' : callingAPI }"
        :disabled="callingAPI"
        @click="save"
      >
        <Preloader v-if="callingAPI" class="save-button__loader" />
        <i v-else class="fa fa-save" />
        {{ $t('buttons.save') }}
      </button>
    </template>
    <template v-else>
      <div v-if="left">
        <app-input
          v-model="asset.headline"
          id="asset_headline"
          :label="$t('dam.headline')"
          :show-change-checkbox="bulkEdit"
          @change-checked="inputChanged('headline', $event)"
        />
        <AuthorSearchSelect
          v-model="asset.author"
          :show-change-checkbox="bulkEdit"
          :required="!isPdf"
          @blur="!bulkEdit && $v.asset.author.$touch()"
          :error="!bulkEdit && $v.asset.author.$error"
          @change-checked="inputChanged('author', $event)"
          id="asset_author"
        />
        <SourceSearchSelect
          v-model="asset.source"
          :show-change-checkbox="bulkEdit"
          @change-checked="inputChanged('source', $event)"
          id="asset_source"
        />
        <app-input
          v-model="asset.caption"
          id="asset_caption"
          :label="$t('dam.caption')"
          :show-change-checkbox="bulkEdit"
          @change-checked="inputChanged('caption', $event)"
        >
        </app-input>
        <app-input
          v-model="asset.captionWriter"
          id="asset_captionWriter"
          :label="$t('dam.caption_writer')"
          :show-change-checkbox="bulkEdit"
          @change-checked="inputChanged('captionWriter', $event)"
        >
        </app-input>
        <app-input
          v-model="asset.keywords"
          id="asset_keywords"
          :label="$t('dam.keywords')"
          :show-change-checkbox="bulkEdit"
          @change-checked="inputChanged('keywords', $event)"
        >
        </app-input>
        <app-textarea
          v-model="asset.description"
          :label="isPdf ? $t('dam.description_short') : `${$t('dam.description')} *`"
          :show-change-checkbox="bulkEdit"
          @blur="!bulkEdit && $v.asset.description.$touch()"
          :error="!bulkEdit && $v.asset.description.$error"
          @change-checked="inputChanged('description', $event)"
          id="asset_description"
        >
          <app-tooltip
            v-if="!isPdf"
            :title="$t('dam.headline_seo_info')"
            icon="fab fa-google"
            customInfoClass="seo"
          ></app-tooltip>
        </app-textarea>
        <app-input
          v-if="!isPdf"
          v-model.trim="asset.imageAltText"
          :label="$tc('dam.imageAltText')"
          :show-change-checkbox="bulkEdit"
          @blur="!bulkEdit && $v.asset.imageAltText.$touch()"
          :error="!bulkEdit && $v.asset.imageAltText.$error && vlm"
          @change-checked="inputChanged('imageAltText', $event)"
          id="asset_imageAltText"
          :required="vlm"
        />
      </div>
      <div v-if="right">
        <div v-if="!isPdf">
          <app-input
            v-model="asset.city"
            id="asset_city"
            :label="$t('dam.city')"
            :show-change-checkbox="bulkEdit"
            @change-checked="inputChanged('city', $event)"
          >
          </app-input>
          <app-input
            v-model="asset.country"
            id="asset_country"
            :label="$t('dam.country')"
            :show-change-checkbox="bulkEdit"
            @change-checked="inputChanged('country', $event)"
          >
          </app-input>
          <app-input
            v-model="asset.countryCode"
            id="asset_countryCode"
            :label="$t('dam.country_code')"
            :show-change-checkbox="bulkEdit"
            @change-checked="inputChanged('countryCode', $event)"
          >
          </app-input>
        </div>
        <app-input
          v-model="asset.credit"
          id="asset_credit"
          :label="$t('dam.credit')"
          :show-change-checkbox="bulkEdit"
          @change-checked="inputChanged('credit', $event)"
        >
        </app-input>
        <app-input
          v-if="!isPdf"
          :value="asset.personsInImage ? asset.personsInImage.toString() : ''"
          @input="(value) => asset.personsInImage = value ? value.split(',').map(v => v.trim()) : []"
          id="asset_personsInImage"
          :label="$t('dam.person_in_image')"
          :show-change-checkbox="bulkEdit"
          @change-checked="inputChanged('personsInImage', $event)"
        >
        </app-input>
        <app-input
          v-model="asset.rights"
          id="asset_rights"
          :label="$t('dam.rights')"
          :show-change-checkbox="bulkEdit"
          @change-checked="inputChanged('rights', $event)"
        >
        </app-input>
        <app-input
          :value="getUploadSource(asset)"
          id="asset_upload_source"
          :label="$t('dam.upload_source')"
          disabled
        >
        </app-input>
        <MultiSelect
          v-if="isPdf"
          :value="damCategories.find(category => category.id === +asset.categoryCode)"
          @input="categoryObject => asset.categoryCode = categoryObject ? categoryObject.id: null"
          id="asset_category"
          :label-desc="$t('dam.category')"
          :options="damCategories"
          :multiple="false"
          :close-on-select="true"
          :show-change-checkbox="bulkEdit"
          @change-checked="inputChanged('categoryCode', $event)"
          label="title"
          track-by="id"
        />
        <div v-if="!isPdf">
          <MultiSelect
            :value="asset.siteLocks ? asset.siteLocks.map(siteName => ({ name: siteName, title: getSiteTitle(siteName) })): []"
            @input="(value) => asset.siteLocks = value ? value.map(v => v.name) : []"
            :options="restrictedSites"
            :placeholder="$t('dam.filter.restricted_sites_placeholder')"
            :label-desc="$t('dam.restricted_sites')"
            :show-change-checkbox="bulkEdit"
            @change-checked="inputChanged('siteLocks', $event)"
            label="title"
            track-by="name"
            id="asset_siteLocks"
            data-attr="asset_siteLocks"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Input from '../../form/inputs/Input'
import Textarea from '../../form/Textarea'
import NotifyService from '../../../services/NotifyService'
import Tooltip from '../../tooltip/Tooltip'
import DamPdfCategoryMixin from '../../mixins/valueObject/DamPdfCategoryMixin'
import Preloader from '@/components/preloader/Preloader'
import MultiSelect from '@/components/form/select/MultiSelect'
import AuthorSearchSelect from '@/components/author/AuthorSearchSelect'
import SourceSearchSelect from '@/components/author/SourceSearchSelect'
import { required } from 'vuelidate/lib/validators'
import { mapGetters, mapState } from 'vuex'
import { AVAILABLE_SOURCE_MAP } from '@/model/ValueObject/DamUploadSources'
import { MODULE_DAM } from '@/model/ValueObject/UserPermissionModules'

export default {
  name: 'AssetEditForm',
  mixins: [DamPdfCategoryMixin],
  props: {
    asset: {
      type: Object
    },
    header: {
      type: Boolean,
      default: false
    },
    left: {
      type: Boolean,
      default: false
    },
    right: {
      type: Boolean,
      default: false
    },
    bulkEdit: {
      type: Boolean,
      default: false
    },
    bulkEditAssetsIds: {
      type: Array,
      required: false
    },
    changedInputs: {
      type: Object,
      required: false
    }
  },
  components: {
    Preloader,
    appInput: Input,
    appTextarea: Textarea,
    appTooltip: Tooltip,
    MultiSelect,
    AuthorSearchSelect,
    SourceSearchSelect
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    ...mapGetters('dam', ['isPdf']),
    ...mapGetters(['vlm']),
    restrictedSites () {
      return this.$store.getters['site/enabledSites'](MODULE_DAM)
    },
    getSiteTitle () {
      return this.$store.getters['site/getSiteTitle']
    }
  },
  validations: {
    asset: {
      author: {
        required
      },
      description: {
        required
      },
      imageAltText: {
        required
      }
    }
  },
  methods: {
    inputChanged (key, changed) {
      if (this.bulkEdit) {
        if (changed) {
          this.changedInputs[key] = true
        } else {
          delete this.changedInputs[key]
        }
      } else {
        this.changedInputs[key] = true
      }
    },
    getUploadSource (asset) {
      return AVAILABLE_SOURCE_MAP[asset.uploadSourceCode]
    },
    getActionAndPayloadForSave () {
      let action
      let payload
      if (this.bulkEdit) {
        action = 'dam/updateMetadataBulkSingle'
        payload = { entityUuids: this.bulkEditAssetsIds }
      } else {
        action = 'dam/updateMetadata'
        payload = { entityUuid: this.asset.entityUuid }
      }
      Object.keys(this.changedInputs).forEach(key => {
        payload[key] = this.asset[key]
      })
      return { action, payload }
    },
    async save () {
      if (Object.keys(this.changedInputs).length === 0) {
        NotifyService.setSuccessMessage(this.$t('notify.record_was_updated'))
        this.$emit('close')
        return
      }
      this.$v.$touch()
      if (!this.isPdf && !this.bulkEdit && this.$v.$invalid) {
        this.$emit('touch-validation')
        NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
        return
      }
      const { action, payload } = this.getActionAndPayloadForSave()
      this.$store.dispatch(action, payload)
        .then(() => {
          this.$store.commit('TOGGLE_LOADING')
          if (this.$store.getters['dam/error'] === null) {
            setTimeout(() => {
              NotifyService.setSuccessMessage(this.$t('notify.record_was_updated'))
              this.$store.commit('TOGGLE_LOADING')
              this.$emit('post-update')
              this.$emit('close')
            }, 1000) // for modal-windows we need to wait as 'elastic' holds old data in cache
          } else {
            this.$store.commit('TOGGLE_LOADING')
            NotifyService.setErrorMessage(this.$store.getters['dam/error'])
          }
        })
        .catch(error => console.error(error))
    }
  },
  mounted () {
    if (this.bulkEdit) {
      // TODO
    }
    // this.restrictedSitesUnique = this.asset.nmhTransmissionReferenceCodes
    // .concat(this.asset.transmissionReference)
    // .filter((elem, pos, arr) => {
    //   return arr.indexOf(elem) === pos && elem !== 'none'
    // })
    // .join(',')
  }
}
</script>

<style scoped lang="scss">
.form-button {
  @include font(700 13px "Roboto");
  border-radius: rem(6px);
  border: 1px solid #D1DBE4;
  cursor: pointer;
  background-color: #6599FE;
  color: #FFFFFF;
  height: rem(30px);
  width: rem(100px);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: rem(5px);
  position: relative;
  &:hover {
    background-color: lighten(#6599FE, 5%);
  }
  &--disabled {
    background-color: #D1DBE4;
    cursor: default;
    &:hover {
      background-color: #D1DBE4;
    }
  }
}
.save-button {
  &__loader {
    position: absolute;
    transform: scale(0.5);
    left: 0;
  }

}
.restricted-sites-label--change-checkbox {
  padding-left: rem(25px);
}
</style>
